export default defineNuxtRouteMiddleware((to) => {
  const { isLoggedIn } = useUserStore()

  if (isLoggedIn) {
    return
  }

  const { redirectToSignup } = useRedirectToSignup()
  try {
    return redirectToSignup({ next: to.fullPath })
  }
  catch (_e) {
    return abortNavigation()
  }
})
